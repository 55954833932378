import React from 'react';
import styled, { css } from 'styled-components';
import { Head, Footer, Header, Loading, Sidemenu } from '@/components/shared';

const StandardLayout = ({ children, title }) => {
  return (
    <React.Fragment>
      <Head title={title} />
      <Header />
      <Main>
        <MainInner>
          {children}
          <Sidemenu />
        </MainInner>
      </Main>
      <Footer />
    </React.Fragment>
  );
};

const Main = styled.main`
  width: 100%;
  min-width: 1250px;
  margin: auto;
  flex: 1;
`;
const MainInner = styled.div`
  width: 90%;
  max-width: 1150px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

const PageTitleDiv = styled.div`
  text-align: center;
  background-image: url(${(props) => props.imgUrl});
  width: 100%;
  height: 395px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageTitleTextDiv = styled.div`
  width: 540px;
  height: 160px;
  background-color: rgba(255, 255, 255, 0.92);
  /* 背景色指定 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleE = styled.p`
  font-size: 1.4rem;
  ${(props) => getStyle(props)}
`;
const getStyle = (props) => {
  if (props.colorType == 'red') {
    return `
	  color: #ea614e;
	  `;
  } else if (props.colorType === 'blue') {
    return `
	color: #3bb1c9;
		  `;
  } else if (props.colorType === 'green') {
    return `
	color: #43cc4a;
		  `;
  }
};

export default StandardLayout;
