import React from 'react';
import styled, { css } from 'styled-components';
import { Head, Header, Loading } from '@/components/shared';

const SigninLayout = ({ children, title }) => {
  return (
    <React.Fragment>
      <Head title={title} />
      <header>
        <HeaderInner>
          <div css="width:30%;max-width:330px;min-width:230px;">
            <img src="/images/logo.png" alt="" css="cursor:pointer;width: 100%;height: auto;" />
          </div>
        </HeaderInner>
      </header>
      <Main>{children}</Main>
    </React.Fragment>
  );
};
const HeaderInner = styled.div`
  width: 90%;
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 140px;
`;
const Main = styled.main`
  width: 100%;
  min-width: 1250px;
  margin: auto;
  flex: 1;
`;

export default SigninLayout;
